"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.lastminuteMessages = void 0;
var _reactIntl = require("react-intl");
/* eslint-disable import/prefer-default-export */

const lastminuteMessages = exports.lastminuteMessages = (0, _reactIntl.defineMessages)({
  name: {
    "id": "bjc4HX",
    "defaultMessage": [{
      "type": 0,
      "value": "Lastminute voordeel"
    }]
  },
  description: {
    "id": "CH3mnV",
    "defaultMessage": [{
      "type": 0,
      "value": "Op het laatste moment een vakantie of weekendje weg boeken? Texel heeft een uitgebreid aanbod last minute aanbiedingen, waarmee u profiteert van aantrekkelijke kortingen! Wat dacht u van een vakantiehuis op een bungalowpark of vakantiepark met zwembad? Of overnacht u liever in een luxe hotel? Op Texel is ook een ruime keuze aan stacaravans en chalets. Hier vindt u die gezellige Bed en Breakfast die precies bij u past. Wilt u Texel eens op een heel andere manier beleven, kijk dan bij de bijzondere overnachtingen, zoals een yurt of misschien wel een pipowagen. Dat is vrijheid: pak maar vast uw koffer en boek snel een last minute op Texel! VVV Texel toont hier alle last minute aanbiedingen overzichtelijk op basis van accommodatiesoort. U kunt in het zoekveld ook zoeken naar aankomst op langere termijn of naar accommodaties waarin honden welkom zijn."
    }]
  }
});