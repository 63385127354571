"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getPropsForTripType = exports.default = void 0;
var _Button = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/action/Button"));
var _Body = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Body"));
var _Title = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Title"));
var _react = _interopRequireDefault(require("react"));
var _reactIntl = require("react-intl");
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _Cell = _interopRequireDefault(require("./designsystem/Cell"));
var _Stack = _interopRequireDefault(require("./Stack"));
var _graphql = require("../generated/graphql");
var _tripTypeMessages = require("../constants/tripTypeMessages");
var __jsx = _react.default.createElement;
const getPropsForTripType = (tripType, intl) => {
  switch (tripType) {
    case _graphql.TripTypeEnum.LastMinute:
      return {
        name: intl.formatMessage(_tripTypeMessages.lastminuteMessages.name),
        description: intl.formatMessage(_tripTypeMessages.lastminuteMessages.description)
      };
    default:
      return undefined;
  }
};
exports.getPropsForTripType = getPropsForTripType;
const SearchSpecial = ({
  name,
  description,
  landingPageUrl
}) => __jsx(_Cell.default, {
  gridColumn: ['1 / -1', null, null, '1 / 9']
}, __jsx(_Stack.default, {
  spacing: [2, null, null, 5],
  style: {
    maxWidth: '93rem'
  }
}, __jsx(_Title.default, {
  variant: "large"
}, name), __jsx(_Body.default, null, description), landingPageUrl && __jsx(_Cell.default, {
  gridColumn: ['1, -1', '1 / 4']
}, __jsx(SpecialsButton, {
  variant: "outline"
}, __jsx("a", {
  href: landingPageUrl
}, __jsx(_reactIntl.FormattedMessage, {
  id: "4OJWXf",
  defaultMessage: [{
    "type": 0,
    "value": "Lees de actievoorwaarden"
  }]
}))))));
var _default = exports.default = SearchSpecial;
const SpecialsButton = (0, _styledComponents.default)(_Button.default).withConfig({
  displayName: "SearchSpecial__SpecialsButton",
  componentId: "sc-1kyclop-0"
})(["width:100%;font-weight:700;border-radius:0.8rem;margin-top:", ";@media screen and (min-width:", "){margin-top:0;}@media screen and (min-width:", "){margin-top:", ";}a{color:currentColor;text-decoration:none;}"], ({
  theme
}) => theme.spacing['50_Semi'], ({
  theme
}) => theme.mediaQueries.m, ({
  theme
}) => theme.mediaQueries.xl, ({
  theme
}) => theme.spacing['50_Semi']);