"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.query = exports.default = void 0;
var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _Body = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Body"));
var _Title = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Title"));
var _devicesize = require("@oberoninternal/travelbase-ds/context/devicesize");
var _grid = require("@rebass/grid");
var _client = require("@apollo/client");
var _addDays = _interopRequireDefault(require("date-fns/addDays"));
var _eachDayOfInterval = _interopRequireDefault(require("date-fns/eachDayOfInterval"));
var _isBefore = _interopRequireDefault(require("date-fns/isBefore"));
var _isSameDay = _interopRequireDefault(require("date-fns/isSameDay"));
var _startOfToday = _interopRequireDefault(require("date-fns/startOfToday"));
var _link = _interopRequireDefault(require("next/link"));
var _router = require("next/router");
var _react = _interopRequireDefault(require("react"));
var _reactIntersectionObserver = require("react-intersection-observer");
var _reactIntl = require("react-intl");
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _parseToDateString = _interopRequireDefault(require("../constants/parseToDateString"));
var _graphql = require("../generated/graphql");
var _Heading = _interopRequireDefault(require("./designsystem/Heading"));
var _Divider = _interopRequireDefault(require("./Divider"));
var _SearchDateHitCount = _interopRequireDefault(require("./SearchDateHitCount"));
var _Stack = _interopRequireDefault(require("./Stack"));
var __jsx = _react.default.createElement;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const query = exports.query = (0, _client.gql)`
    query UnitSearchAlternativeDates($params: UnitAndTripSearchParamsInput!) {
        unitSearchAlternativeDates(params: $params) {
            hits
            date
        }
    }
`;
const correctRange = (index, min, visible) => {
  if (index >= min) {
    return [index - min, index + (visible - min)];
  }
  return [0, visible];
};
const getVisibleRange = (deviceSize, index, count) => {
  if ((deviceSize === 'tablet' || deviceSize === 'laptop') && count >= 11) {
    return correctRange(index, 5, 11);
  }
  if (deviceSize === 'mobile' && count >= 9) {
    return correctRange(index, 4, 9);
  }
  return [0];
};
const SearchAlternativeDates = ({
  lastSearchParams
}) => {
  const {
    data,
    loading
  } = (0, _graphql.useUnitSearchAlternativeDatesQuery)({
    variables: {
      params: lastSearchParams
    }
  });
  const [inViewRef, isInView] = (0, _reactIntersectionObserver.useInView)({
    triggerOnce: true
  });
  const {
    query: params,
    pathname
  } = (0, _router.useRouter)();
  const deviceSize = (0, _devicesize.useDeviceSize)();
  if (loading || !data?.unitSearchAlternativeDates.length || !deviceSize) {
    return null;
  }
  const {
    unitSearchAlternativeDates
  } = data;
  const midDate = new Date(lastSearchParams.date);
  const dateHits = (0, _eachDayOfInterval.default)({
    start: (0, _addDays.default)(midDate, -7),
    end: (0, _addDays.default)(midDate, 7)
  }).reduce((acc, next) => {
    if ((0, _isBefore.default)(next, (0, _startOfToday.default)())) {
      return acc;
    }
    const foundHit = unitSearchAlternativeDates.find(dateHit => (0, _isSameDay.default)(new Date(dateHit.date), next));
    if (!foundHit) {
      return [...acc, {
        date: (0, _parseToDateString.default)(next),
        hits: 0
      }];
    }
    return [...acc, foundHit];
  }, []);
  const index = dateHits.map(({
    date
  }) => date).indexOf(lastSearchParams.date);
  const activeHit = dateHits[index];
  const range = getVisibleRange(deviceSize, index, dateHits.length);
  const inViewHits = dateHits.slice(...range);
  const mostHitsAvailable = Math.max(...dateHits.map(({
    hits
  }) => hits));
  const getLinkProps = date => ({
    href: {
      query: _objectSpread(_objectSpread({}, params), {}, {
        arrivalDate: date,
        departureDate: (0, _parseToDateString.default)((0, _addDays.default)(new Date(date), lastSearchParams.duration))
      }),
      pathname
    },
    passHref: true,
    shallow: true,
    scroll: true
  });
  return __jsx(Container, null, __jsx(_Divider.default, {
    my: 6
  }), __jsx(_Heading.default, null, __jsx(_Title.default, null, __jsx(_reactIntl.FormattedMessage, {
    id: "t1mh3S",
    defaultMessage: [{
      "type": 0,
      "value": "Voor "
    }, {
      "type": 1,
      "value": "nights"
    }, {
      "type": 0,
      "value": " nachten is er op de huidige aankomstdag weinig aanbod"
    }],
    values: {
      nights: lastSearchParams.duration
    }
  }))), __jsx(_Body.default, null, __jsx(_reactIntl.FormattedMessage, {
    id: "kCELbr",
    defaultMessage: [{
      "type": 0,
      "value": "Kies eventueel een andere aankomstdag voor meer aanbod"
    }]
  })), __jsx(_grid.Box, {
    className: "gt-s"
  }, __jsx(DaysGrid, {
    style: {
      paddingTop: '4rem',
      overflow: 'hidden'
    },
    ref: inViewRef
  }, inViewHits.map(({
    date,
    hits
  }, i) => __jsx(_link.default, (0, _extends2.default)({
    key: date
  }, getLinkProps(date), {
    legacyBehavior: true
  }), __jsx(_SearchDateHitCount.default, {
    animate: isInView,
    variant: "desktop",
    hits: hits,
    mostHits: mostHitsAvailable,
    index: i,
    active: date === activeHit?.date
  })))), __jsx(_Divider.default, {
    mt: 0,
    mb: 3,
    as: "span",
    style: {
      display: 'block'
    }
  }), __jsx(DaysGrid, null, inViewHits.map(({
    date
  }) => __jsx(_grid.Flex, {
    key: date,
    flexDirection: "column",
    alignItems: "center"
  }, __jsx(Weekday, {
    className: "gt-xl"
  }, __jsx(_reactIntl.FormattedDate, {
    value: date,
    weekday: "long"
  })), __jsx(Weekday, {
    className: "lt-xl"
  }, __jsx(_reactIntl.FormattedDate, {
    value: date,
    weekday: "short"
  })), __jsx(DayMonth, null, __jsx(_reactIntl.FormattedDate, {
    value: date,
    day: "numeric",
    month: "short"
  })))))), __jsx(_Stack.default, {
    className: "lt-s",
    spacing: 3,
    mt: 4
  }, inViewHits.map(({
    date,
    hits
  }) => __jsx(_link.default, (0, _extends2.default)({
    key: date
  }, getLinkProps(date), {
    legacyBehavior: true
  }), __jsx(_SearchDateHitCount.default, {
    variant: "mobile",
    hits: hits,
    active: date === activeHit?.date
  }, __jsx(_grid.Flex, {
    key: date,
    flexDirection: "column"
  }, __jsx(Weekday, null, __jsx(_reactIntl.FormattedDate, {
    value: date,
    weekday: "long"
  })), __jsx(DayMonth, {
    className: "gt-s"
  }, __jsx(_reactIntl.FormattedDate, {
    value: date,
    day: "numeric",
    month: "short"
  })), __jsx(DayMonth, {
    className: "lt-s"
  }, __jsx(_reactIntl.FormattedDate, {
    value: date,
    day: "numeric",
    month: "long"
  }))))))), __jsx(_Divider.default, {
    className: "lt-s"
  }));
};
var _default = exports.default = SearchAlternativeDates;
const Container = _styledComponents.default.div.withConfig({
  displayName: "SearchAlternativeDates__Container",
  componentId: "uho6od-0"
})(["grid-column:1 / -1;"]);
const Weekday = (0, _styledComponents.default)(_Body.default).attrs({
  variant: 'tiny'
}).withConfig({
  displayName: "SearchAlternativeDates__Weekday",
  componentId: "uho6od-1"
})(["color:", ";@media screen and (min-width:", "){color:", ";}font-size:12px;"], ({
  theme
}) => theme.colors.neutral['50'], ({
  theme
}) => theme.mediaQueries.s, ({
  theme
}) => theme.colors.neutral['40']);
const DayMonth = (0, _styledComponents.default)(_Body.default).withConfig({
  displayName: "SearchAlternativeDates__DayMonth",
  componentId: "uho6od-2"
})(["@media screen and (min-width:", "){color:", ";font-weight:400;}font-weight:600;font-size:14px;"], ({
  theme
}) => theme.mediaQueries.s, ({
  theme
}) => theme.colors.neutral['50']);
const DaysGrid = _styledComponents.default.div.withConfig({
  displayName: "SearchAlternativeDates__DaysGrid",
  componentId: "uho6od-3"
})(["display:flex;> *{flex:1;+ *{margin-left:1rem;}}"]);